import { Button } from '@components/ui'
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import classNames from 'classnames'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState, useCallback } from 'react'
import styles from './styles.module.scss'

interface urlSettings {
  url_us: string
  url_au: string
}

interface LocaleSwitcherSettings {
  show: boolean
  sticky: boolean
  switchRef: string
  country_text: {
    [country: string]: {
      code: string
      title: string
      continue: string
    }
  }
  de_country: string[]
  eu_country: string[]
  au_country?: string[]
  urlDataList?: urlSettings[]
  close_cache: number
}

const COUNTRY_TO_LOCALE: Record<string, string> = {
  de: 'eu-de',
  eu: 'eu-en',
}

const LOCALE_TO_COUNTRY: Record<string, string> = {
  'eu-de': 'de',
  'eu-en': 'eu',
}

function LocaleSwitcher({
  settings,
}: {
  settings: Partial<LocaleSwitcherSettings>
}) {
  const {
    country_text = {},
    switchRef = '',
    eu_country = [],
    de_country = [],
    au_country = [],
    urlDataList = [],
    close_cache = 14,
  } = settings || {}

  const [currentCountryCode, setCurrentCountryCode] = useState('')

  const [visible, setVisible] = useState(false)
  const { locale = '', locales = [], asPath } = useRouter()
  const pathname = asPath?.split('?')?.[0]?.split('#')?.[0]

  const [selectedLocale, setSelectedLocale] = useState(locale)
  const [openSelect, setOpenSelect] = useState(false)

  const [selectList, setSelectList] = useState<
    { label: string; value: string }[]
  >([])
  const [text, setText] = useState({ title: '', button: '' })

  const selectMap = useMemo(() => {
    const map: Record<string, string> = {}
    selectList.forEach((item) => {
      map[item.value] = item.label
    })
    return map
  }, [selectList])

  const handleClose = useCallback(() => {
    setVisible(false)
    Cookies.set('localeswitcher', 'close', {
      path: '/',
      expires: close_cache,
    })
    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'country_switch',
      eventAction: 'off',
      eventLabel: '',
    })
  }, [close_cache])

  useEffect(() => {
    const closedLocalSwitcher = Cookies.get('localeswitcher')

    if (closedLocalSwitcher) {
      return
    }

    fetch('/geolocation').then(async (res) => {
      const result = await res.json()
      const countryCode = result?.geo?.country?.code
      setCurrentCountryCode(countryCode)
    })
  }, [])

  useEffect(() => {
    if (!currentCountryCode) {
      return
    }

    try {
      if (localStorage) {
        localStorage.removeItem('suggestLocale')
      }
    } catch (_) {}

    let suggestLocale = currentCountryCode.toLowerCase()

    // 未选择过国家
    if (eu_country.includes(currentCountryCode)) {
      // 欧洲国家
      suggestLocale = 'eu-en'
    } else if (au_country.includes(currentCountryCode)) {
      suggestLocale = 'au'
    } else if (de_country.includes(currentCountryCode)) {
      suggestLocale = 'eu-de'
    } else if (currentCountryCode === 'GB') {
      suggestLocale = 'uk'
    }

    suggestLocale = suggestLocale.toLowerCase()

    if (!locales.includes(suggestLocale)) {
      return
    }

    try {
      if (localStorage) {
        localStorage.setItem('suggestLocale', suggestLocale)
      }
    } catch (_) {}

    if (suggestLocale === 'au' && locale !== suggestLocale) {
      const urlData = urlDataList.find((item) =>
        new RegExp(item?.url_us)?.test(pathname)
      )
      if (urlData && urlData?.url_au) {
        window.location.href = `${urlData?.url_au}?ref=${switchRef.replace(
          '$country',
          locale
        )}`
      }
    }

    const countryText =
      country_text[LOCALE_TO_COUNTRY[suggestLocale] || suggestLocale]

    if (locale === suggestLocale || !countryText) {
      return
    }

    const currentSelectList = Object.entries(country_text).map(
      ([key, value]) => ({
        label: value.code,
        value: COUNTRY_TO_LOCALE[key] || key,
      })
    )
    const currentText = {
      title: countryText.title,
      button: countryText.continue,
    }

    setSelectList(currentSelectList)
    setText(currentText)

    setVisible(true)
    setSelectedLocale(suggestLocale)
    window.addEventListener('gtmLoaded', () => {
      pageGTMEvent({
        event: 'uaEvent',
        eventCategory: 'country_switch',
        eventAction: 'popup',
        eventLabel: 'view',
      })
    })
  }, [
    currentCountryCode,
    country_text,
    de_country,
    eu_country,
    au_country,
    locales,
    locale,
    switchRef,
    urlDataList,
    pathname,
  ])

  return (
    <div
      className={classNames(
        `relative z-[1001] h-0
        bg-white transition-[height] duration-300 l:block min-md:px-[40px] min-l:px-[80px]`,
        visible
          ? 'h-auto min-h-[60px] py-[12px] md:min-h-[98px] md:px-[24px] md-l:min-h-[86px]'
          : '',
        visible ? styles.animationBlink : '',
        openSelect ? 'overflow-visible' : 'overflow-hidden'
      )}
      onClick={() => {
        setOpenSelect(false)
      }}
    >
      <div className="mx-auto flex h-full max-w-[1280px] items-center l:flex-col l:items-start">
        <div
          className={classNames(
            `flex w-full items-center leading-[1.167] l:mb-[12px] min-l:mr-[40px] min-l:flex-1`,
            visible ? styles.animationBlinkText : ''
          )}
        >
          <div className="flex-1 font-medium l:mr-[24px]">{text?.title}</div>
          <CloseButton className="min-l:hidden" onClick={handleClose} />
        </div>
        <div className="flex items-center md:w-full">
          <div className="relative mr-[8px] flex h-[34px] w-[270px] items-center text-[14px] md:w-[257px] md:flex-1">
            <div
              className={classNames(
                `flex h-full w-full cursor-pointer items-center rounded-[4px]
                border-2 border-solid border-[#F7F8FA] px-[12px] transition-colors duration-200 
                hover:border-[#00A9E1]`,
                openSelect
                  ? 'border-[#00A9E1] border-b-transparent bg-white'
                  : 'bg-[#F7F8FA]'
              )}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setOpenSelect(!openSelect)
              }}
            >
              <div className="mr-[14px] flex-1 font-medium">
                {selectMap[selectedLocale]}
              </div>
              <div className="w-[14px]">
                <ChevronDownIcon></ChevronDownIcon>
              </div>
            </div>
            <div
              className={classNames(
                `absolute left-0 top-[31px] w-full rounded-bl-[4px] rounded-br-[4px] border-2 border-t-0 border-solid border-[#00A9E1] bg-white`,
                openSelect ? '' : 'hidden'
              )}
            >
              {selectList?.map((item) => (
                <div
                  key={item.label}
                  className="flex h-[34px] cursor-pointer items-center px-[12px] font-medium hover:bg-[#F7F8FA]"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    if (item.value) {
                      setSelectedLocale(item.value)
                      setOpenSelect(false)
                    }
                  }}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </div>
          <Button
            className={classNames(
              'h-[34px] w-auto min-w-[78px] hover:!bg-[#02b8f4] min-l:mr-[15px]',
              styles.button,
              visible ? styles.animationBlinkButton : ''
            )}
            onClick={() => {
              pageGTMEvent({
                event: 'uaEvent',
                eventCategory: 'country_switch',
                eventAction: 'popup',
                eventLabel: 'click',
              })
              window.location.href = `/${selectedLocale}${pathname}?ref=${switchRef.replace(
                '$country',
                locale
              )}`
            }}
          >
            {text?.button}
          </Button>
        </div>
        <CloseButton className="l:hidden" onClick={handleClose} />
      </div>
    </div>
  )
}

export default LocaleSwitcher

function CloseButton({
  className = '',
  onClick,
}: {
  className?: string
  onClick: () => void
}) {
  return (
    <div
      className={classNames('h-[16px] w-[16px] cursor-pointer', className)}
      onClick={onClick}
    >
      <XMarkIcon></XMarkIcon>
    </div>
  )
}
